import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaterialImports } from '../../shared/imports/material';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { StudentsComponent } from './students/students.component';
import { AdminNewslettersComponent } from './admin-newsletters/admin-newsletters.component';
import { AdminLibraryComponent } from './admin-library/admin-library.component';
import { AdminGalleryComponent } from './admin-gallery/admin-gallery.component';



@NgModule({
  declarations: [DashboardComponent, StudentsComponent, AdminNewslettersComponent, AdminLibraryComponent, AdminGalleryComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ReactiveFormsModule,
    ...MaterialImports,
    NgxEditorModule
  ]
})
export class AdminModule { }
