import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { uuidv4 } from '@firebase/util';

@Component({
  selector: 'app-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['./admin-gallery.component.scss']
})
export class AdminGalleryComponent implements OnInit {
  uploadFiles: FileList;

  flsForm: FormGroup = this.fb.group({
    description: ['', [Validators.required]],
  });

  constructor(
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public fb: FormBuilder,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  onFileChange(event: any) {
    this.uploadFiles = null;
    if (event.target.files.length === 0) return;
    this.uploadFiles = (event.target.files);
  }

  onUploadFiles(): void {
    let fileUrls = [];
    if (this.uploadFiles.length === 0) return;
    for (let i = 0; i < this.uploadFiles.length; i ++) {
      const file = this.uploadFiles[i];
      const filePath = uuidv4();
      const ref = this.storage.ref(filePath);
      ref.put(file).then(res => {
        ref.getDownloadURL().subscribe(u => fileUrls.push(u)).add(() => {
          if (i === (this.uploadFiles.length - 1)) {
            this.onAddFiles(fileUrls)
          }
        })
      }).catch(err => this.snackBar.open('An Error Occurred, Try again', 'Okay'));
    }
  }

   onAddFiles(urls: any[]) {
    const { valid, value } = this.flsForm;
    if (!valid) return;
    this.firestore.collection('files').add({
      description: value.description,
      url: urls.toString()
    }).then(
      res => this.snackBar.open('Files Uploaded Successfully', 'Okay')
    ).catch(
      err => this.snackBar.open('An Error Occured, Kindly Retry', 'Okay')
    );
  }

}
