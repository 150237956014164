<div class="container-fluid main">
<h2 mat-dialog-title>Newsletters</h2>
<div mat-dialog-content class="mat-typography">
  <!-- <mat-accordion *ngFor="let item of newsletters">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{item.title}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      {{item.description}}
    </mat-expansion-panel>
  </mat-accordion> -->
  <div *ngFor="let item of newsletters" style="margin-top: 20px;">
    <!-- <mat-card>
      <mat-card-title>{{item.title}}</mat-card-title>
      <mat-card-content [innerHTML]="item.description">{{item.description}}</mat-card-content>
    </mat-card> -->
    <mat-card>
      <mat-card-header>
          <mat-card-title style="font-size: 20px;">{{item.title}}</mat-card-title>
      </mat-card-header>

      <mat-card-content *ngIf="!collapsed" [innerHTML]="item.description">
        {{item.description}}
      </mat-card-content>
      <mat-card-actions>
          <button mat-button color="primary" (click)="collapsed=true">Close text</button>
          <button mat-button color="primary" (click)="collapsed=false">Open text</button>
      </mat-card-actions>
  </mat-card>
  </div>
<div mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  </div>
</div>

</div>
