<div class="container-fluid library">
<div>
  <mat-card *ngFor="let item of libfiles">
    <mat-card-title>{{item.description}}</mat-card-title>
    <mat-card-content>
      <a href={{item.url}} target="_blank">
        <button type="button" mat-raised-button color="primary">Open</button>
      </a>
      </mat-card-content>
  </mat-card>
</div>
</div>
