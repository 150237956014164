<div class="container-fluid about">
<mat-tab-group dynamicHeight>
  <mat-tab label="The School">
    <mat-card-title >About St Monica's</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background">
      <p>
      The school started as a day harambee school for girls in <b>1972</b> under the sponsorship 
      of the Catholic Church. Its uniform constituted of navy blue skirt and pull-over 
      coupled with white socks and black shoes to complete the picture of then, Kitale 
      Girls (K.G). It offered its first students for the National examination; the Kenya 
      Certificate of Education (KCE) in <b>1975</b>. Kitale Girls then assumed Government status 
      in <b>1981</b>.
      </p>
      <p>
        The behavior and academic performance of the girls continued to be dismal and various 
        stakeholders worked hard to change the bad fortunes. They for instance changed the uniform 
        to a red skirt and pullover in <b>1988</b> hoping that this would spur a positive change in both 
        behavior and performance. On the contrary, this was not the case, the school mean continued 
        to drop and the girls' bad reputation even brought them a nickname from Kitale town's dwellers; 
        "The Red Devils" and indeed they were devils in red. This negative trend alarmed the then 
        Bishop of the Catholic Diocese of Eldoret (The late Bishop Cornelius Korir) who called the 
        stakeholders for a meeting and unanimously agreed on a radical change in both the name and the 
        school uniform in <b>1992</b>.
      </p>
      <p>
        The Bishop settled on <b>St. Monica</b> as the patron saint of the school, to help change the 
        school through her prayer and intercession. In January <b>1993</b> the school's name was changed 
        from Kitale Girls Secondary School to <b>St. Monica's Girls High School-Kitale</b>. 
        The change was made official by the government in <b>1996</b> when the school was re-registered. 
        The uniform was changed to the beautiful one we know today; <b>ripple green skirt, white blouse, and grey pullover</b>. 
        It also became a full boarding school after the day section was abolished. The school 
        fortunes started changing gradually and the discipline of the girls also improved drastically. 
        Currently, the school records good performance in terms of both academics and discipline.
      </p>
    </div>
    <mat-card-title>Religion</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      The school is predominantly Catholic. However being an Extra-County school that draws students from
      all over the country, St. Monica's High School- Kitale has both protestant and Muslim populations.
    </div>
  </mat-tab>
  <mat-tab label="Administration">
    <mat-card-title>Board of Management</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      <p>The board of management in a high school is typically responsible for the overall management and administration of the school. The specific duties of the board of management may vary depending on the school, but typically include the following:</p>
      <ul>
        <li>Setting policies</li>
        <li>Managing finances</li>
        <li>Hiring staff</li>
        <li>Evaluating performance</li>
        <li>Planning for the future</li>
      </ul>
      <p>Overall, the board of management plays a critical role in ensuring the success of a high school by providing strategic direction and oversight to the school's operations.</p>
    </div>
    <mat-card-title>PTA</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background">
      <p>Our Parents Association is a vital part of our school community, working in partnership with our faculty and staff to provide support and resources for our students. The PA is made up of dedicated parents who are committed to ensuring that our school provides the best possible education and experience for our students. They play an active role in the school's decision-making process and provide valuable input and feedback.</p>
      <p>Also, the PA supports a variety of initiatives and programs that enhance our students' educational experience. They sponsor events and provide resources that benefit our students and staff.</p>
      <p>The association serves as a bridge between our school and the broader community, helping to build strong relationships and partnerships that benefit our students and families.</p>
    </div>
    <mat-card-title>Chief Principal</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      <p>Dear Students, Parents, and Visitors,</p>
      <p>It is my pleasure to welcome you to St. Monica's Girls High school's website. As the Principal of this esteemed institution, I am honored to lead a dedicated team of teachers and staff members who are committed to providing our students with an exceptional education.</p>
      <p>Our high school is a vibrant community that fosters academic excellence, personal growth, and social responsibility. We strive to create a safe and inclusive environment that supports our students' diverse talents and interests. Our curriculum is designed to challenge and inspire our students, preparing them for success in university and beyond.</p>
      <p>At our high school, we believe in providing our students with a well-rounded education that extends beyond the classroom. Our students have access to a wide range of extracurricular activities, clubs, and sports teams that allow them to explore their passions and develop important life skills.</p>
      <p>We are proud of our high school's strong tradition of academic achievement, and we are committed to continuing to raise the bar for excellence. Our dedicated teachers are constantly striving to improve their craft and provide our students with the best possible learning experience.</p>
    </div>
    <mat-card-title>Deputy Principal (Administration)</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background">
      <p>As the Deputy Principal in charge of administration and discipline, I would like to take this opportunity to emphasize the importance of maintaining a safe and secure environment for our young women to learn and grow.</p>
      <p>We believe that discipline is critical in ensuring that our students can focus on their studies and personal development in a supportive and nurturing environment. Our school has a zero-tolerance policy towards any form of misconduct, including bullying, harassment, discrimination, substance abuse, and any behavior that may endanger the physical or emotional well-being of our students.</p>
      <p>We understand that disciplinary issues can arise from time to time, and we have put in place a robust disciplinary framework to address them. Our disciplinary measures are fair and impartial, and take into account the individual circumstances of each case. We also provide counseling and support services to help students who may be struggling with personal or emotional challenges.</p>
      <p>We encourage parents to work in partnership with us to reinforce the values of respect, empathy, and compassion in their daughters. We also urge our students to take ownership of their behavior and to understand that their actions have consequences.</p>
      <p>Our school community is committed to creating a safe and inclusive environment that fosters learning, personal growth, and academic excellence for our young women. We believe that by working together, we can achieve this goal and ensure that our students reach their full potential.</p>
      <p>Thank you for your continued support</p>
    </div>
    <mat-card-title>Deputy Principal (Academics)</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      <p>As the Deputy Principal in charge of academics at our high school, I am dedicated to ensuring that every student receives a high-quality education that prepares them for success in college and beyond. Our academic programs are designed to challenge and inspire our students, and we offer a wide range of courses and programs that allow them to explore their interests and develop their skills.</p>
      <p>At our high school, we believe that academic success is about more than just grades. We are committed to promoting a growth mindset, encouraging our students to embrace challenges, and teaching them to develop strong study habits and time management skills.</p>
      <p>As the administrator in charge of academic performance, I work closely with our teaching staff to develop and implement curriculum, design assessments, and evaluate student progress. We believe in a data-driven approach to education, and we use a variety of assessment tools to track student growth and ensure that every student is receiving the support they need to succeed.</p>
      <p>In addition to our academic programs, we offer a variety of extracurricular activities, clubs, and sports teams that allow our students to explore their interests and develop important life skills. We believe that a well-rounded education is essential for success, and we encourage our students to get involved in our school community and take advantage of all that we have to offer.</p>
    </div>
  </mat-tab>
  <mat-tab label="Students & Staff">
    <mat-card-title>Students</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background">
     <p>The school's students population stands at 1964 students. Our school is home to a diverse group of students from various backgrounds and experiences. We celebrate this diversity and believe that it enriches our community and enhances the educational experience for all of our students.</p>
     <p>The students are well-rounded individuals committed to their academic and personal growth. They are confident, independent thinkers who are prepared to take on the challenges of the future.</p>

    </div>
    <mat-card-title>Teachers</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      <p>The school boasts over 65 employed teachers who are passionate and dedicated educators committed to helping our students achieve their full potential. They are highly qualified professionals and experts in their subject areas and are committed to helping each student fulfill their potential. They inspire and challenge our girls to think critically, learn deeply, and explore their interests.</p>
      <p>Furthermore, they are caring and supportive mentors who foster positive relationships with their students. They provide guidance and encouragement both inside and outside of the classroom, helping our girls to develop the confidence and skills they need to succeed.</p>
    </div>
    <mat-card-title>Non-teaching Staff</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background">
      Our non-teaching staff is an integral part of our school community, providing essential services and support to ensure that our students have a safe and nurturing environment in which to learn and grow.
      From our front office staff to our custodians and maintenance crew, the subordinate staff works behind the scenes to ensure that our school runs smoothly and that our students have the resources and support they need to succeed.

    </div>
  </mat-tab>
  <mat-tab label="Academics & Departments">
    <mat-card-title>Academics</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background" style="align-items: center;">
      St. Monica's High School-Ktl follows a 8-4-4 system of Education with the curriculum offered by KCID.
      <h2>The following subjects are offered in the school</h2>
      <table>
        <tr>
          <td><ul><li>English</li></ul></td>
          <td><ul><li>Kiswahili</li></ul></td>
          <td><ul><li>Mathematics</li></ul></td>
        </tr>
        <tr>
          <td><ul><li>Biology</li></ul></td>
          <td><ul><li>Physics</li></ul></td>
          <td><ul><li>Chemistry</li></ul></td>
        </tr>
        <tr>
          <td><ul><li>Geography</li></ul></td>
          <td><ul><li>History</li></ul></td>
          <td><ul><li>C.R.E</li></ul></td>
        </tr>
        <tr>
          <td><ul><li>Angriculture</li></ul></td>
          <td><ul><li>Computer</li></ul></td>
          <td><ul><li>Home Science</li></ul></td>
        </tr>
        <tr>
          <td><ul><li>Business Studies</li></ul></td>
          <td><ul><li>French</li></ul></td>
        </tr>
      </table>
    </div>
    <mat-card-title>Departments</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      The school is endowed with the several departments as follows:
      <ul>
        <li>Languages Department</li>
        <li>Mathematics Department</li>
        <li>Science Department</li>
        <li>Humanities Department</li>
        <li>Games Department</li>
        <li>Clubs and societies Department</li>
        <li>Guidance and counselling Department</li>
        <li>Technicals and creative arts Department</li>
      </ul>
    </div>
  </mat-tab>
  <mat-tab label="Co-curricular Activities">
    <mat-card-title>Sports</mat-card-title>
    <div class="example-large-box mat-elevation-z4 green-background">
      <p>The school is a participant in the national sports festival.
        <b>The following games are offered at the school:</b>
        athletics, badminton, basketball, football (soccer), handball, hockey, lawn tennis, rugby football,
        swimming  table tennis and chess.
      </p>
      <p>St. Monica's Girls-Kitale prides itself in being East Africa champions in Table Tennis,
        Badminton and Chess.
      </p>
    </div>
    <mat-card-title>Clubs and Societies</mat-card-title>
    <div class="example-large-box mat-elevation-z4 grey-background">
      <p>We deliver an several and  rigorous clubs and societies that are enhanced with robust
        activities and enrichment programs that stretches the boundaries of our students’ abilities
        with a dedicated and talented team of staff, who act as their patrons, hard-working and
        exceptionally delightful learners who are rich with new ideas. Some of the clubs and societies
        in the school are the YCS movement,  CU movement, SDA movement, muslim movement  scouts,
        Drama club, Debating club, Journalism club among many others.
      </p>
    </div>
  </mat-tab>
</mat-tab-group>

</div>
