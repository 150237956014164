<div class="container-fluid gallery">
  <div class="row justify-content-center">
    <div class="col-sm-6" *ngFor="let item of files">
      <mat-card>
        <mat-card-title>{{item.description}}</mat-card-title>
        <mat-card-content>
          <carousel [images]="(item.url | fileUrls)" [height]="480" [loop]="true" [autoplay]="false " [dots]="true" [cellWidth]="480"
          [arrowsTheme]="'dark'" [counter]="true" [autoplayInterval]="4000"></carousel>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
