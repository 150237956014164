import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth/auth.component';
import { AboutComponent } from './components/about/about.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { IndexComponent } from './components/index/index.component';
import { LibraryComponent } from './components/library/library.component';
import{ SidenavComponent } from './components/sidenav/sidenav.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent
  },
  {
    path: '',
    component: SidenavComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'gallery',
    component: GalleryComponent
  },
  {
    path: 'library',
    component: LibraryComponent
  },
  {
    path: 'auth/welcome',
    component: AuthComponent
  },
  // Containers
  {
    path: 'admin',
    loadChildren: () => import('./containers/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'teachers',
    loadChildren: () => import('./containers/teachers/teachers.module').then(m => m.TeachersModule)
  },
  {
    path: 'parents',
    loadChildren: () => import('./containers/parents/parents.module').then(m => m.ParentsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
