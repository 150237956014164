import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { AdminModule } from './containers/admin/admin.module';
import{ TeachersModule } from './containers/teachers/teachers.module';
import{ ParentsModule } from './containers/parents/parents.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialImports } from './shared/imports/material';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { IndexComponent } from './components/index/index.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { AuthComponent } from './auth/auth/auth.component';
import { AboutComponent } from './components/about/about.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { LocationComponent } from './components/location/location.component';
import{ NewslettersComponent } from './components/newsletters/newsletters.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { LibraryComponent } from './components/library/library.component';
import { environment } from 'src/environments/environment';
import { FileUrlsPipe } from './shared/pipes/file-urls.pipe';

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    IndexComponent,
    AuthComponent,
    AboutComponent,
    GalleryComponent,
    NewslettersComponent,
    LocationComponent,
    LibraryComponent,
    FileUrlsPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    AdminModule,
    TeachersModule,
    ParentsModule,
    MatDialogModule,
    ...MaterialImports,
    IvyCarouselModule,
    MDBBootstrapModule.forRoot(),
    NgxEditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
