<mat-card>
  <mat-card-title>Location</mat-card-title>
  <mat-card-content>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe width="600" height="500" id="gmap_canvas"
        src="https://maps.google.com/maps?q=st%20monicas%20kitale&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        <a href="https://123movies-to.org"></a><br>
        <style>.mapouter{position:relative;text-align:right;height:500px;width:600px;}</style>
        <a href="https://www.embedgooglemap.net">embedgooglemap.net</a>
        <style>.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:600px;}</style>
      </div>
    </div>
  <button mat-button mat-dialog-close>Close</button>
  </mat-card-content>
</mat-card>
