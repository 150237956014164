import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAbGDMNNQfAGNOf2RTZHUm8c6VRnXU7yrk",
  authDomain: "smghs-techbird56.firebaseapp.com",
  projectId: "smghs-techbird56",
  storageBucket: "smghs-techbird56.appspot.com",
  messagingSenderId: "207722648954",
  appId: "1:207722648954:web:3868048769e5cac9d432c3",
  measurementId: "G-D1MZ576XX2"
};

export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
