import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileUrls'
})
export class FileUrlsPipe implements PipeTransform {

  transform(value: string): any[] {
    let newUrls: any[] = []
    value.split(',').map(v => {
      newUrls.push({ path: v })
    });
    return newUrls;
  }

}
