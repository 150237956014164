import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../../global';
import { NewslettersComponent } from '../newsletters/newsletters.component';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [Globals]
})
export class IndexComponent implements OnInit {

  carouselImages: any[] = [
    {
      path: './assets/images/gallery/students.jpeg'
    },
    {
      path: './assets/images/gallery/1017345c-bd2c-49bd-b487-6e45f975c6ed.jpeg'
    },
    {
      path: './assets/images/gallery/7c10f68a-ca87-4db8-ba6d-4772653409ff.jpeg'
    },
    {
      path: './assets/images/gallery/32ce7166-88c2-4fd5-a545-2769fe564fa0.jpeg'
    },
    {
      path: './assets/images/gallery/4875a42a-919c-4f80-a3d6-478620fe9908.jpeg'
    },
    {
      path: './assets/images/gallery/a2c9410d-a3bd-46a2-b293-42ac5018c40a.jpeg'
    },
    {
      path: './assets/images/gallery/d47c24b6-eb3a-4e3f-a019-211e708ee22e.jpeg'
    },
    {
      path: './assets/images/gallery/d6548f53-b5e2-42b4-8edd-2db34313a9dc.jpeg'
    },
    {
      path: './assets/images/gallery/f36ec58a-2854-4cd7-a1f8-e1da0bfea55e.jpeg'
    }
  ];

  constructor(
    public globals: Globals,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }
  openDialog() {
    const dialogRef = this.dialog.open(NewslettersComponent);
    dialogRef.afterClosed().subscribe(result => {});
  }

}
