import { Injectable } from '@angular/core';

@Injectable()
export class Globals {

  schoolName: string = "St. Monica's Girls High School Kitale";
  schoolAbbr: string = "SMGHS";
  schoolMotto: string = "Par Excellence";
  schoolMission: string = "To offer quality education that will prepare the girl child to effectively serve humanity";
  schoolVision: string = "To be a girls' school of choice nationally";

}
