<div class="container-fluid p-4" style="margin-top: 72px;">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <mat-card>
        <mat-card-title>Add Images to Gallery</mat-card-title>
        <mat-card-content>
          <form [formGroup]="flsForm">
            <mat-form-field style="width: 96%;">
              <input matInput formControlName="description" placeholder="Description">
            </mat-form-field>
            <input type="file" (change)="onFileChange($event)" multiple placeholder="Choose Files">
            <button action="default" mat-raised-button color="primary" (click)="onUploadFiles()">Upload Files</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
