import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ParentsRoutingModule } from './parents-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResultsComponent } from './results/results.component';


@NgModule({
  declarations: [DashboardComponent, ResultsComponent],
  imports: [
    CommonModule,
    ParentsRoutingModule
  ]
})
export class ParentsModule { }
