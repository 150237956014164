<div class="container-fluid p-4" style="margin-top: 64px;">
  <div class="form-outline w-100">
    <mat-card>
      <mat-card-title>Add Newsletter</mat-card-title>
      <mat-card-content>
        <form [formGroup]="nlForm">
          <mat-form-field style="width: 96%;">
            <input matInput formControlName="title" placeholder="Title">
          </mat-form-field>
            <div  style="width: 96%; margin: 2%">
              <ngx-editor-menu [editor]="ngxEditor"></ngx-editor-menu>
              <ngx-editor [editor]="ngxEditor" placeholder="Newsleter Content..." formControlName="description"></ngx-editor>
            </div>
          <button action="default" mat-raised-button color="primary" (click)="onAddNewsletter()">Add Newsletter</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="container-fluid p-4" style="margin-top: 24px">
  <mat-card>
    <mat-card-title>Manage Newsletters</mat-card-title>
    <mat-card-content>
      <div class="row p-2" *ngFor="let item of newsletters; let i = index">
        <div class="col-1">{{(i + 1)}}.</div>
        <div class="col-9">{{item?.title}}</div>
        <div class="col-2">
          <button mat-button color="warn" (click)="deleteNewsletter(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
