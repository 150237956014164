import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Editor } from 'ngx-editor';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit{
  ngxEditor: Editor;
  uploadFiles: FileList;
  stdntsForm: FormGroup = this.fb.group({
    admno: ['001', [Validators.required]],
    class: ['1', [Validators.required]],
    stream: ['East', [Validators.required]],
    doa: ['31/05/2020', [Validators.required]],
    dob: ['31/05/1997', [Validators.required]],
    fullName: ['Student One', [Validators.required]],
    phoneNumber: ['070000000', [Validators.required]],
  });

  uploadLibFiles: any;
  constructor(
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public fb: FormBuilder,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.firestore.collection('students').get().subscribe(
      res => console.log(res.docs),
      err => console.log(err)
    )
  }

  onAddStudent(): void {
    const { valid, value } = this.stdntsForm;
    if (!valid) return;
    this.firestore.collection('students').add({ ...value }).then(
      res => this.snackBar.open('Student Added Successfully', 'Okay')
    ).catch(
      err => this.snackBar.open('An Error Occured, Kindly Retry', 'Okay')
    );
  }

}
