import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

interface IFiles {
  description: string,
  urls: []
}
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  files: any[];
  filesRef: AngularFirestoreCollection<IFiles[]> = null;
  constructor(
    public firestore: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.filesRef = this.firestore.collection('/files');
  }

  ngOnInit(): void {
    this.filesRef.valueChanges().subscribe(f => this.files = f);
  }

}
