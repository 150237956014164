import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

interface LFiles {
  description: string,
  urls: []
}
@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  libfiles: any[];
  filesRef: AngularFirestoreCollection<LFiles[]> = null;
  constructor(
    public firestore: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.filesRef = this.firestore.collection('/libfiles');
  }

  ngOnInit(): void {
    this.filesRef.valueChanges().subscribe(f => this.libfiles = f);
  }

  // constructor() { }

  // ngOnInit(): void {
  // }

}
