<div class="container-fluid main">
  <h2>FEE STRUCTURE</h2>

  <table style="width: 100%;">
  <tr>
      <th>Term</th>
      <th>Form 1</th>
      <th>Form 2</th>
      <th>Form 3</th>
      <th>Form 4</th>
  </tr>

  <tr>
      <th>Term 1</th>
      <td>xxxxx</td>
      <td>xxxxx</td>
      <td>xxxxx</td>
      <td>xxxxx</td>
  </tr>

  <tr>
      <th>Term 2</th>
      <td>xxxxx</td>
      <td>xxxxx</td>
      <td>xxxxx</td>
      <td>xxxxx</td>
  </tr>
  <tr>
      <th>Term 3</th>
      <td>xxxxx</td>
      <td>xxxxx</td>
      <td>xxxxx</td>
      <td>xxxxx</td>
  </tr>
  </table>
  <h3>Bank Accounts</h3>
  <p>Kenya Commercial Bank (KCB): Acc No; xxxxxxxxxxx</p>
  <p>Standard Chartered: Acc No; xxxxxxxxxxx</p>
  <p>Co-operative: Acc No; xxxxxxxxxxx</p>
  <p>Equity bank: Acc No; xxxxxxxxxxx</p>

  </div>



