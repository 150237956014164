import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Globals } from 'src/app/global';
import { TokenService } from 'src/app/shared/services/token.service';
import { LocationComponent } from '../location/location.component';
import { NewslettersComponent } from '../newsletters/newsletters.component';

@Component({
  selector: 'app-sidenav',
  styleUrls: ['./sidenav.component.scss'],
  templateUrl: './sidenav.component.html',
  providers: [Globals]
})
export class SidenavComponent implements OnInit {

  sidenavWidth = 16;
  ngStyle: string;
  showFiller = false;
  sidenavOpen: boolean = false;
  sidenavMode: string = 'side';

  isAdmin: boolean = false;
  isParent: boolean = false;

  constructor(
    public globals: Globals,
    public router: Router,
    private location: Location,
    private dialog: MatDialog,
    private tokenService: TokenService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWindowSize();
  }

  ngOnInit() {
    this.checkWindowSize();
    if (this.location.path().includes('admin')) this.isAdmin = true;
    if (this.location.path().includes('parent')) this.isParent = true
  }

  checkWindowSize(): void {
    if (window.innerWidth >= 992) {
      this.sidenavOpen = true;
      this.sidenavMode = 'side';
    } else {
      this.sidenavOpen = false;
      this.sidenavMode = 'over';
    }
  }

  onClick(): void{
    if (window.innerWidth <=992){
      this.sidenavOpen = !this.sidenavOpen;
    }else{
      this.sidenavOpen = true;
    }
  }

  onLogin(): void {
    this.router.navigate(['/auth/welcome'])
  }

  increase() {
    this.sidenavWidth = 15;
  }
  decrease() {
    this.sidenavWidth = 4;
  }

  openDialog() {
    const dialogRef = this.dialog.open(NewslettersComponent);
    dialogRef.afterClosed().subscribe(result => {});
  }
  openLocationDialog() {
    const dialogRef = this.dialog.open(LocationComponent);
    dialogRef.afterClosed().subscribe(result => {});
  }

}
