import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { uuidv4 } from '@firebase/util';

interface LFiles {
  description: string,
  urls: []
}
@Component({
  selector: 'app-admin-library',
  templateUrl: './admin-library.component.html',
  styleUrls: ['./admin-library.component.scss']
})
export class AdminLibraryComponent implements OnInit {
  libForm: FormGroup = this.fb.group({
    description: ['', [Validators.required]],
  });
  uploadLibFiles: any;

  libfiles: any[];
  filesRef: AngularFirestoreCollection<LFiles[]> = null;
  constructor(
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public fb: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.filesRef = this.firestore.collection('/libfiles');
   }

  ngOnInit(): void {
    this.filesRef.valueChanges({ idField: 'id' }).subscribe(f => { this.libfiles = f; console.log(f) });
  }

  onLibFileChange(event: any) {
    this.uploadLibFiles = null;
    if (event.target.files.length === 0) return;
    this.uploadLibFiles = (event.target.files);
  }

  onUploadLibFiles(): void {
    let fileUrls = [];
    if (this.uploadLibFiles.length === 0) return;
    for (let i = 0; i < this.uploadLibFiles.length; i ++) {
      const file = this.uploadLibFiles[i];
      const filePath = uuidv4();
      const ref = this.storage.ref(filePath);
      ref.put(file).then(res => {
        ref.getDownloadURL().subscribe(u => fileUrls.push(u)).add(() => {
          if (i === (this.uploadLibFiles.length - 1)) {
            this.onAddLibFiles(fileUrls)
          }
        })
      }).catch(err => this.snackBar.open('An Error Occurred, Try again', 'Okay'));
    }
  }

  onAddLibFiles(urls: any[]) {
    const { valid, value } = this.libForm;
    if (!valid) return;
    this.firestore.collection('libfiles').add({
      description: value.description,
      url: urls.toString()
    }).then(
      res => this.snackBar.open('Files Uploaded Successfully', 'Okay')
    ).catch(
      err => this.snackBar.open('An Error Occured, Kindly Retry', 'Okay')
    );
  }

  deleteLibItem(payload: any) {
    this.filesRef.doc(payload.id).delete();
  }

}
