import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Editor } from 'ngx-editor';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { uuidv4 } from '@firebase/util';
interface nlFiles {
  title: string,
  description: string
}
@Component({
  selector: 'app-admin-newsletters',
  templateUrl: './admin-newsletters.component.html',
  styleUrls: ['./admin-newsletters.component.scss']
})
export class AdminNewslettersComponent implements OnInit, OnDestroy {
  ngxEditor: Editor;
  uploadFiles: FileList;
  newsletters: any[];
  nlRef: AngularFirestoreCollection<nlFiles[]> = null;
  nlForm: FormGroup = this.fb.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]]
  });

  constructor(
    public firestore: AngularFirestore,
    public storage: AngularFireStorage,
    public fb: FormBuilder,
    public snackBar: MatSnackBar
  ) {
    this.nlRef = this.firestore.collection('/newsletters');
  }
  ngOnInit(): void {
    this.ngxEditor = new Editor();
    this.nlRef.valueChanges({ idField: 'id' }).subscribe(f => this.newsletters = f);
  }
  ngOnDestroy(): void {
    this.ngxEditor.destroy();
  }

  onAddNewsletter(): void {
    const { valid, value } = this.nlForm;
    if (!valid) return;
    this.firestore.collection('newsletters').add({ ...value }).then(
      res => this.snackBar.open('Newsletter Added Successfully', 'Okay')
    ).catch(
      err => this.snackBar.open('An Error Occured, Kindly Retry', 'Okay')
    );
  }

  deleteNewsletter(payload: any) {
    this.nlRef.doc(payload.id).delete();
  }

}
