<div class="container-fluid p-4" style="margin-top: 72px;">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <mat-card>
      <mat-card-title>Add File to Library</mat-card-title>
      <mat-card-subtitle style="color: red;">Only files in PDF format</mat-card-subtitle>
      <mat-card-content>
        <form [formGroup]="libForm">
          <mat-form-field style="width: 96%;">
            <input matInput formControlName="description" placeholder="Description">
          </mat-form-field>
          <input type="file" (change)="onLibFileChange($event)" placeholder="Choose Files">
          <button action="default" mat-raised-button color="primary" (click)="onUploadLibFiles()">Upload Files</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="container-fluid p-4" style="margin-top: 24px">
    <mat-card>
      <mat-card-title>Manage Library</mat-card-title>
      <mat-card-content>
        <div class="row p-2" *ngFor="let item of libfiles; let i = index">
          <div class="col-1">{{(i + 1)}}.</div>
          <div class="col-9">{{item?.description}}</div>
          <div class="col-2">
            <button mat-button color="warn" (click)="deleteLibItem(item)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
