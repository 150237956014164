<div class="container-fluid main">
  <div class="row justify-content-center">
    <h1>{{globals.schoolName}}</h1>
  </div>
  <div class="row justify-content-center">
    <h3>{{globals.schoolMotto}}</h3>
  </div>
</div>

<div class="container-fluid mt-4">
  <carousel [images]="carouselImages" [height]="480" [loop]="true" [autoplay]="true" [dots]="true" [cellWidth]="480"
    [arrowsTheme]="'dark'" [counter]="true" [autoplayInterval]="4000"></carousel>
</div>

<div class="container-fluid p-4" style="margin-top: 56px;">
    <h1 style="color: #00695c;"><u>About the school</u></h1>
  <mat-card class="index-card">
    <img src="../../../assets/images/gallery/32ce7166-88c2-4fd5-a545-2769fe564fa0.jpeg" alt="">
        <mat-card-content>
          <p>St. Monica's Girls High School-Kitale is an outstanding school in the heart of Kitale,
            Trans-Nzoia County. It is a girls only boarding school where the desire to learn is
            infectious and students of all abilities flourish because everyone works hard to create a
            learning environment where everybody is welcome and diversity is celebrated.
            </p>
          <p>Everything at St. Monica's Girls High School-Kitale is designed to encourage students to
            take an active role in their learning. Every day is fresh and inspiring and our efforts
            are geared towards keeping our students enthralled, hungry and engaged. Simply put we don’t
            just teach we inspire.
          </p>
          </mat-card-content>
      </mat-card>
  </div>


  <div class="container" style="text-align: center;">
    <div class="row">
      <div class="col-sm-4">
        <div>
  <mat-card class="mission-card">
    <h1 style="color: #00695c;"><u>Our Mission</u></h1>
    <img src="../../../assets/images/gallery/2922242_26964.jpg" alt="">
        <mat-card-content>
          <p>{{globals.schoolMission}}</p>
        </mat-card-content>
      </mat-card>
        </div>
      </div>
      <div class="col-sm-4">
        <div>
  <mat-card class="mission-card">
    <h1 style="color: #00695c;"><u>Our Vision</u></h1>
    <img src="../../../assets/images/gallery/magnifying-glass-enlarging-word.jpg" alt="">
        <mat-card-content>
          <p>{{globals.schoolVision}}</p>
        </mat-card-content>
      </mat-card>
        </div>
      </div>
      <div class="col-sm-4">
        <div>
  <mat-card class="mission-card">
    <h1 style="color: #00695c;"><u>Our Values</u></h1>
    <img src="../../../assets/images/gallery/17743187_5874782.jpg" alt="">
        <mat-card-content>
          <h3>R.I.G.H.T</h3>
          <p>Respect, Integrity, Godliness, Humility, Teamwork</p>
        </mat-card-content>
      </mat-card>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>


<!-- Footer -->
<footer class="page-footer font-small unique-color-dark">

  <div style="background-color: #00695c">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h4 class="mb-0">{{globals.schoolName}}</h4>
        </div>


      </div>

    </div>
  </div>

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3">

      <!-- Grid column -->
      <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

        <!-- Content -->
        <h3 class="text-uppercase font-weight-bold">School Mission</h3>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 80px;">
        <p>{{globals.schoolMission}}</p>
        <h3 class="text-uppercase font-weight-bold">School Vision</h3>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 80px;">
        <p>{{globals.schoolVision}}</p>

      </div>
      <!-- Grid column -->

      <!--Grid column-->
      <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
        <h5 class="text-uppercase font-weight-bold">Quick Links</h5>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 80px;">
        <ul class="list-unstyled mb-0">
          <li>
            <a href="javascript:void(0)" (click)="openDialog()" class="text-white">Newsletters</a>
          </li>
          <li>
            <a [routerLink]="['/about']" class="text-white">About</a>
          </li>
          <li>
            <a [routerLink]="['/library']" class="text-white">Library</a>
          </li>
          <li>
            <a [routerLink]="['/gallery']" class="text-white">Gallery</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contacts</h6>
        <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 80px;">
        <p><mat-icon> email</mat-icon>st.monicasgirlskitale@yahoo.com</p>
        <p><mat-icon> call</mat-icon>0727239595</p>
        <p><mat-icon> call</mat-icon>0724347156</p>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->


</footer>
<!-- Footer -->
