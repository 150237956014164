import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

interface nlFiles {
  title: string,
  descriptin: string
}
@Component({
  selector: 'NewslettersComponent',
  templateUrl: './newsletters.component.html'
})
export class NewslettersComponent implements OnInit{
  newsletters: any[];
  nlRef: AngularFirestoreCollection<nlFiles[]> = null;
  constructor(
    public firestore: AngularFirestore,
    private snackBar: MatSnackBar
  ) {
    this.nlRef = this.firestore.collection('/newsletters');
  }

  ngOnInit(): void {
    this.nlRef.valueChanges().subscribe(f => this.newsletters = f);
  }
}

