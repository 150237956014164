<div class="container-fluid auth-container">
  <div class="row justify-content-center align-items-center">
    <mat-card>
      <mat-card-title>{{globals.schoolName}}</mat-card-title>
      <mat-card-subtitle>Login to proceed</mat-card-subtitle>
      <form [formGroup]="authForm">
        <mat-card-content>
          <mat-form-field>
            <input matInput formControlName="username" placeholder="Username / Admission Number">
          </mat-form-field>
        </mat-card-content>
        <mat-card-content>
          <mat-form-field>
            <input type="password" matInput formControlName="password" placeholder="Password">
          </mat-form-field>
        </mat-card-content>
        <mat-card-content>
          <button type="button" class="float-right" mat-raised-button color="primary" (click)="onAuth()">Login</button>
          <button type="button" class="float-right" mat-raised-button color="warn" (click)="onReset()">Reset</button>
        </mat-card-content>
      </form>
    </mat-card>
  </div>
</div>
