<mat-sidenav-container fullscreen class="sidenav-container">
  <mat-sidenav #sidenav [mode]="sidenavMode" [opened]="sidenavOpen"
  [ngStyle]="{ 'width.em': 18 }">
    <mat-toolbar color="primary">
      <img src="./assets/images/IMG-20220217-WA0003.jpg" style="width: auto; height: 64px; padding-left: 0;" alt="">
      <span>{{globals.schoolAbbr}}</span>

    </mat-toolbar>
    <!-- General -->
    <mat-list class="sidenav-list" *ngIf="!isAdmin && !isParent">
      <a [routerLink]="['/']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">home</mat-icon>
          <div mat-line style="color: #00695c;">Home</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/about']" (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">info_outline</mat-icon>
          <div mat-line>About</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a href="javascript:void(0)" (click)="openLocationDialog()" (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">location_pin</mat-icon>
          <div mat-line>Location</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a href="javascript:void(0)" (click)="openDialog()" (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">library_books</mat-icon>
          <div mat-line>Newsletters</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/library']" (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">library_books</mat-icon>
          <div mat-line>Library</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/gallery']" (click)="sidenav.toggle()">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">photo_album</mat-icon>
          <div mat-line>Gallery</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
        <mat-list-item  [matMenuTriggerFor]="contactsMenu" style="cursor: pointer;">
          <mat-icon mat-list-icon color="primary">phone</mat-icon>
          <div mat-line>
            Contacts
            <mat-menu #contactsMenu="matMenu" >
              <mat-card>
                <p><mat-icon> email</mat-icon>st.monicasgirlskitale@yahoo.com</p>
                <p><mat-icon> call</mat-icon>0727239595</p>
                <p><mat-icon> call</mat-icon>0724347156</p>
              </mat-card>
            </mat-menu>
          </div>
        </mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    <!-- Admin -->
    <mat-list class="sidenav-list" *ngIf="isAdmin">
      <a [routerLink]="['/admin/dashboard']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">home</mat-icon>
          <div mat-line style="color: #00695c;">Admin Dashboard</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/admin/students']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">how_to_reg</mat-icon>
          <div mat-line style="color: #00695c;">Students</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/admin/admin-newsletters']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">library_books</mat-icon>
          <div mat-line style="color: #00695c;">Newsletters</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/admin/admin-library']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">library_books</mat-icon>
          <div mat-line style="color: #00695c;">Library</div>
        </mat-list-item>
      </a>
      <mat-divider></mat-divider>
      <a [routerLink]="['/admin/admin-gallery']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">photo_album</mat-icon>
          <div mat-line style="color: #00695c;">Gallery</div>
        </mat-list-item>
      </a>
    </mat-list>

    <!-- Parent -->
    <mat-list class="sidenav-list" *ngIf="isParent">
      <a [routerLink]="['/parent/dashboard']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">home</mat-icon>
          <div mat-line style="color: #00695c;">Dashboard</div>
        </mat-list-item>
      </a>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list class="sidenav-list" *ngIf="isParent">
      <a [routerLink]="['/parent/dashboard']">
        <mat-list-item>
          <mat-icon mat-list-icon color="primary">library_books</mat-icon>
          <div mat-line style="color: #00695c;">Student Results</div>
        </mat-list-item>
      </a>
    </mat-list>
  </mat-sidenav>
  <div>
    <mat-toolbar color="primary" class="header">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span class="spacer"></span>
      <div *ngIf="router.url === '/admin/dashboard'; else elseBlock">
        <button class="top-nav" mat-button (click)="onLogin()">
          Logout
        </button>
      </div>
      <ng-template #elseBlock>
        <button class="top-nav" mat-button (click)="onLogin()">
          Login | Sign Up
        </button>
      </ng-template>
    </mat-toolbar>
  </div>
  <router-outlet></router-outlet>
</mat-sidenav-container>
