import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Globals } from '../../global';
import { firebaseApp } from '../../shared/config/firebase/firebase.config';
import { signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import { TokenService } from '../../shared/services/token.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SidenavComponent } from '../../components/sidenav/sidenav.component'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  providers: [Globals]
})
export class AuthComponent implements OnInit {
  authForm: FormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]]
  });

  constructor(
    public globals: Globals,
    private fb: FormBuilder,
    private router: Router,
    private tokenService: TokenService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    signOut(getAuth(firebaseApp));
    this.tokenService.deleteCookie('auth');
  }

  onAuth() {
    const { username, password } = this.authForm.value;
    const auth = getAuth(firebaseApp);
    let newUsername = `${username}@stmonicasgirlskitale.com`;
    signInWithEmailAndPassword(auth, newUsername, password).then(
      userCredential => {
        this.tokenService.setCookie('auth', userCredential.user.refreshToken);
        this.tokenService.setCookie('user', JSON.stringify({
          email: userCredential.user.email
        }));
        this.router.navigateByUrl("/admin/dashboard").then(() => {
          window.location.reload();
        });
    }).catch(error => this.snackBar.open('Invalid username / password', 'Okay'))
  }

  onReset() {
  }

  onSignup() {
    this.router.navigate(['/auth/register']);
  }

}
