<div class="container-fluid p-4" style="margin-top: 72px;">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <mat-card>
        <mat-card-title>Add Students</mat-card-title>
        <mat-card-content>
          <form [formGroup]="stdntsForm">
            <mat-form-field>
              <input matInput formControlName="fullName" placeholder="Full Name">
            </mat-form-field>
            <mat-form-field>
              <input type="number" matInput formControlName="admno" placeholder="Admission Number">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Class</mat-label>
              <mat-select formControlName="class">
                <mat-option value="1">Form One</mat-option>
                <mat-option value="2">Form Two</mat-option>
                <mat-option value="3">Form Three</mat-option>
                <mat-option value="4">Form Four</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Stream</mat-label>
              <mat-select formControlName="stream">
                <mat-option value="East">East</mat-option>
                <mat-option value="West">West</mat-option>
                <mat-option value="North">North</mat-option>
                <mat-option value="South">South</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Year of Admission</mat-label>
              <mat-select formControlName="doa">
                <mat-option *ngFor="let item of [2018, 2019, 2020, 20201, 2022, 2023, 2024]" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="dobPicker" formControlName="dob">
              <mat-datepicker-toggle matIconSuffix [for]="dobPicker"></mat-datepicker-toggle>
              <mat-datepicker #dobPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input type="number" matInput formControlName="phoneNumber" placeholder="Parent's Phone Number">
            </mat-form-field>
            <button action="default" mat-raised-button color="primary" (click)="onAddStudent()">Add Student</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
